import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";
import Accordion from '../../components/accordion/accordion';
import Draggon from '../../components/draggon/draggon';

new LiveEvent('click', '[data-modal]', function openModal(e) {
  e.preventDefault();

  // Закрытие предыдущей модалки
  const closer = document.querySelector('.modal__closer');
  if (closer) {
    closer.dispatchEvent(new Event('click'));
  }

  let link = this;
  let modal = new Modal(this, {
    closerText: '',
    onFormSuccess: function () {
      if (link.dataset.goal) {
        let action = 'click';
        if (link.dataset.goalAction) {
          action = link.dataset.goalAction;
        }
        window.goal(link.dataset.goal, action);
      }
    },
  });
  return false;
});

new Accordion();

const bind = () => {
  const draggonItems = document.querySelectorAll('[data-draggon]');
  draggonItems.forEach((draggonItem, key) => {
    draggonItem.dataset.draggon = `${key}`;
    const draggon = new Draggon(`[data-draggon="${key}"]`);
    draggon.bindEvents();
  });
};

document.addEventListener('DOMContentLoaded', bind);
document.addEventListener('DOMContentMutated', bind);