function getTimeRemaining(endtime) {
  const t = Date.parse(endtime) - Date.parse(new Date());
  const seconds = Math.floor((t / 1000) % 60);
  const minutes = Math.floor((t / 1000 / 60) % 60);
  const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  const days = Math.floor(t / (1000 * 60 * 60 * 24));
  return {
    total: t,
    days,
    hours,
    minutes,
    seconds,
  };
}

function initializeClock(id, endtime) {
  const clocks = document.querySelectorAll('[data-clocks');
  clocks.forEach((clock) => {
    const daysSpan = clock.querySelectorAll('.days');
    const hoursSpan = clock.querySelectorAll('.hours');
    const minutesSpan = clock.querySelectorAll('.minutes');
    const secondsSpan = clock.querySelectorAll('.seconds');
    function updateClock() {
      const t = getTimeRemaining(endtime);
      const timeinterval = setTimeout(updateClock, 1000);

      daysSpan.forEach((day) => {
        day.innerHTML = t.days;
      });
      hoursSpan.forEach((hour) => {
        hour.innerHTML = (`0${t.hours}`).slice(-2);
      });
      minutesSpan.forEach((minute) => {
        minute.innerHTML = (`0${t.minutes}`).slice(-2);
      });
      secondsSpan.forEach((second) => {
        second.innerHTML = (`0${t.seconds}`).slice(-2);
      });
      if (t.total <= 0) {
        clearInterval(timeinterval);
      }
    }

    updateClock();
  });
}

const deadline = 'September 20 2020 00:00:00 GMT+0300';
initializeClock('clock', deadline);